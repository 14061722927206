.shipping-calculation .primary.actions-primary {
    min-width: 100%;
}

button.action.submit.primary.calculate{
    background: #000;
    color: #fff;
    border-radius: 5px;
    margin: 0;
    min-width: 100%;
}

